import React from "react"

import SampleForm from "../components/form/form"

class ContactForm extends React.Component {
	render() {
		return (
			<SampleForm
				form_name="Contact Form"
				form_id="5e61f0c8a5aede4e48e78d36"
				form_classname="contact-form"
				webriq="true"
			>
				<div className="form-row">
					<div className="col-md-12">
						<div className="form-group">
							<label htmlFor="full_name">Name</label>
							<input
								className="form-control"
								type="text"
								name="Name"
								id="full_name"
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="email_address">Email Address</label>
							<input
								className="form-control"
								type="email"
								name="Email Address"
								id="email_address"
								required
							/>
						</div>
						<small className="form-text mt-2 mb-2 text-danger">
							We will never share your email with anyone else.
						</small>
						<div className="form-group">
							<label htmlFor="phone_number">Phone Number</label>
							<input
								className="form-control"
								type="text"
								name="Phone Number"
								id="phone_number"
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="phone_number">Schedule</label>
							<small className="form-text mt-2 mb-2">
								Please select a time to receive a call from our team:
							</small>
							<select id="schedule" name="Schedule" className="form-control">
								<option value="Morning (9-10 AM)">Morning (9-10 AM)</option>
								<option value="Late Morning (10 AM- 12PM)">
									Late Morning (10 AM- 12PM)
								</option>
								<option value="Early Afternoon (12-2 PM)">
									Early Afternoon (12-2 PM)
								</option>
								<option value="lateafternoon">Late Afternoon (2-4 PM)</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="message">Message</label>
							<textarea
								className="form-control"
								type="text"
								rows="5"
								name="Message"
								id="message"
								required
							/>
						</div>
						<div className="webriq-recaptcha" id="rc-imageselect" />
						<button className="btn btn-primary hvr-shadow" type="submit">
							Submit
						</button>
					</div>
				</div>
			</SampleForm>
		)
	}
}
export default ContactForm
