import React from "react"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"
import ContactInfo from "../components/contact-information"

const Contact = ({ data }) => (
	<Layout>
		<SEO
			title="Executive Coaching & Consulting - Business Leadership Coaching"
			description="AGL offers executive & business coaching & consulting leadership services! Schedule your free consultation today & start fostering a growth culture within your organization."
		/>
		<div className="page-headline">
			<div className="container ">
				<div className="section-heading text-center">
					<h6 className="font-weight-bold text-uppercase flair">AGL CO.</h6>
					<h1>
						<strong>CONTACT US</strong>
					</h1>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content contact">
			<div className="container">
				<div className="text-center">
					<h2 className="mb-0	">
						<strong>Experience coaching today!</strong>{" "}
					</h2>
					<strong>
						<p>
							Experience coaching firsthand, and gain clarity around what you
							need and want as a leader.
						</p>
					</strong>
				</div>
				<div className="row justify-content-between pt-5">
					<div className="col-md-6">
						<ContactInfo />
						{/*<iframe
							src="https://calendly.com/coachingforgood/30min?month=2020-03"
							width="100%"
							height="400px"
							max-height="100%"
							frameborder="0"
							style={{
								overflow: "hidden !important",
								marginTop: "20px",
							}}
							title="Calendly"
						></iframe>*/}
					</div>
					<div className="col-md-6">
						<ContactForm />
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Contact
